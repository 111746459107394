import Popup from "components/popups";
import { STATS_TITLES, VOTE_TYPES, TEMP_USER_LIKES_LIMIT, URL } from "const";
import PropTypes from "prop-types";
import { Icon } from "components/basic";
import cn from "classnames";
import { useCallback } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

const popperOptions = {
  strategy: "fixed",
  placement: "top",
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        padding: 10,
      },
    },
    {
      name: "offset",
      options: {
        offset: [0, 10],
      },
    },
  ],
};

function ElementStats({ stats, styles, iconColor, likes, sourceUrl, sourceId }) {
  const { itemStyles = "font-semibold text-black-300", listStyles } = styles;

  const { user, ui } = useStore();

  const defineIconColor = useCallback(
    statsType => {
      if (statsType === "Likes") {
        if (likes) {
          const { currentUserValue } = likes;
          if (currentUserValue === VOTE_TYPES.LIKE) return "text-new-blue-100";
        }
      }

      return iconColor;
    },
    [iconColor, likes]
  );

  const handleLike = useCallback(() => {
    const { currentUserValue, addLike, removeVote } = likes;

    if (
      !user.isLoggedIn &&
      (likes.type === "Board" ||
        likes.type === "ArtworkCollection" ||
        likes.type === "Blogpost" ||
        ((!currentUserValue || currentUserValue === VOTE_TYPES.DISLIKE) &&
          user.userData.totalLikes >= TEMP_USER_LIKES_LIMIT))
    ) {
      ui.authModal.promptRegistration();
    } else if (currentUserValue !== null) {
      if (currentUserValue === VOTE_TYPES.DISLIKE) {
        addLike();
      } else {
        removeVote();
      }
    } else {
      addLike();
    }
  }, [likes, user, ui.authModal]);

  const handleClick = useCallback(
    statsType => {
      switch (statsType) {
        case "Likes":
          if (!likes) return null;

          return handleLike();

        /*
          In case of possible future req. of handling specific stat-button separately - leaving switch structre as is

          case "Comments":
          case "Artworks":
          case "Boards":
          case "Views":
        */

        case "Boards":
          window.open(`${URL.BOARD_INDEX}?artworkId=${sourceId}`, "_blank").focus();
          break;

        default: {
          if (!sourceUrl) return null;

          window.open(`${sourceUrl}`, "_blank").focus();
        }
      }

      return null;
    },
    [handleLike, likes, sourceUrl, sourceId]
  );

  return (
    <div className={cn("ml-auto flex space-x-15 text-14", listStyles)}>
      {stats.map(({ name, value, description }, index) => (
        <Popup key={index} popperOptions={popperOptions}>
          {({ isOpen, toggle }) => [
            <Popup.Button
              onMouseEnter={() => {
                toggle();
              }}
              onMouseLeave={() => {
                if (isOpen) toggle();
              }}
              popupClick={() => handleClick(STATS_TITLES[name].title)}
            >
              <span className={cn("group flex items-center", itemStyles)}>
                <Icon
                  icon={STATS_TITLES[name].icon}
                  className={cn(
                    "mr-5 fill-current",
                    defineIconColor(STATS_TITLES[name].title),
                    STATS_TITLES[name].className || null
                  )}
                />
                {value || "--"}
              </span>
            </Popup.Button>,
            <Popup.Content className="z-100">
              <p className="rounded-5 border border-gray-500 bg-white-900 px-7 py-5 text-13 text-gray-1200 shadow-btn-sm">
                {description || STATS_TITLES[name].description}
              </p>
            </Popup.Content>,
          ]}
        </Popup>
      ))}
    </div>
  );
}

ElementStats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.number })).isRequired,
  styles: PropTypes.shape({ listStyles: PropTypes.string, itemStyles: PropTypes.string }),
  likes: PropTypes.shape({
    type: PropTypes.string,
    currentUserValue: PropTypes.string,
    addLike: PropTypes.func,
    dislike: PropTypes.func,
    removeVote: PropTypes.func,
  }),
  iconColor: PropTypes.string,
  sourceUrl: PropTypes.string,
  sourceId: PropTypes.number,
};

ElementStats.defaultProps = {
  styles: { listStyles: null, itemStyles: null },
  iconColor: "text-gray-1100",
  likes: null,
  sourceUrl: null,
  sourceId: null,
};

export default observer(ElementStats);
