/* eslint-disable no-nested-ternary */
import { useCallback } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import cn from "classnames";
import { TEMP_USER_LIKES_LIMIT, VOTE_TYPES } from "const";
import { Icon, Button as BaseButton } from "./basic";
import Ripples from "./Ripples";

function renderValue(type, value) {
  if (type === VOTE_TYPES.LIKE) {
    return value === VOTE_TYPES.LIKE ? "Liked" : "Like";
  }

  if (type === VOTE_TYPES.DISLIKE) {
    return value === VOTE_TYPES.DISLIKE ? "Disliked" : "Dislike";
  }

  return null;
}

function LikeButton({ type, likes, minified, minifiedSize, btnText, className, color, callback = null, ...props }) {
  const { currentUserValue, addLike, dislike, removeVote } = likes;
  const { user, ui } = useStore();

  let colorSettings;

  switch (color) {
    case "blue":
      colorSettings = {
        button: {
          hover: "group-hover:bg-new-blue-100/70",
          hoverActive: "group-hover:bg-new-blue-100/70",
          active: "bg-new-blue-100",
          inactive: "bg-white-100",
        },
        icon: {
          hover: "group-hover:text-white-100",
          hoverActive: "",
          active: "text-white-100",
          inactive: "text-new-gray-800",
        },
      };
      break;

    case "gray":
      colorSettings = {
        button: {
          hover: "",
          hoverActive: "",
          active: "",
          inactive: "",
        },
        icon: {
          hover: "md:hover:text-new-blue-100",
          hoverActive: "md:hover:text-new-blue-100/70",
          active: "text-new-blue-100",
          inactive: "text-new-gray-800",
        },
      };
      break;

    default:
      colorSettings = {
        button: {
          hover: "",
          hoverActive: "",
          active: "",
          inactive: "",
        },
        icon: {
          hover: "hover:text-white-100",
          hoverActive: "",
          active: "text-white-100",
          inactive: "text-white-100/70",
        },
      };
      break;
  }

  const handleClick = useCallback(
    async function handleClick() {
      if (
        !user.isLoggedIn &&
        type === VOTE_TYPES.LIKE &&
        (likes.type === "Board" ||
          likes.type === "ArtworkCollection" ||
          ((!currentUserValue || currentUserValue === VOTE_TYPES.DISLIKE) &&
            user.userData.totalLikes >= TEMP_USER_LIKES_LIMIT))
      ) {
        ui.authModal.promptRegistration();
      } else {
        if (type === VOTE_TYPES.LIKE) {
          if (currentUserValue) {
            if (currentUserValue === VOTE_TYPES.DISLIKE) {
              await addLike();
            } else {
              await removeVote();
            }
          } else {
            await addLike();
          }
        }

        if (type === VOTE_TYPES.DISLIKE) {
          if (currentUserValue) {
            if (currentUserValue === VOTE_TYPES.LIKE) {
              await dislike();
            } else {
              await removeVote();
            }
          } else {
            await dislike();
          }
        }

        if (callback) callback();
      }
    },
    [likes.type, type, currentUserValue, removeVote, addLike, dislike, user, ui.authModal, callback]
  );

  const defineButtonTitle = useCallback(() => {
    if (type === VOTE_TYPES.LIKE) {
      return currentUserValue === VOTE_TYPES.LIKE ? "Remove like" : "Like";
    }
    return currentUserValue === VOTE_TYPES.DISLIKE ? "Show" : "Hide";
  }, [currentUserValue, type]);

  if (minified) {
    return (
      <Ripples className={cn("group inline-flex", className)} outer color="white">
        <BaseButton
          className={cn(
            "h-full w-full rounded-6 p-5 sm:p-8",
            type === VOTE_TYPES.LIKE
              ? currentUserValue === VOTE_TYPES.LIKE
                ? [colorSettings.button.active, colorSettings.button.hoverActive]
                : [colorSettings.button.inactive, colorSettings.button.hover]
              : currentUserValue === VOTE_TYPES.DISLIKE
              ? [colorSettings.button.active, colorSettings.button.hoverActive]
              : [colorSettings.button.inactive, colorSettings.button.hover]
          )}
          aria-label="Like"
          onClick={handleClick}
          async
          LoadPlaceholder={null}
          {...props}
          title={defineButtonTitle()}
        >
          {type === VOTE_TYPES.LIKE ? (
            <Icon
              icon={currentUserValue === VOTE_TYPES.LIKE ? "heart" : "heart-empty"}
              className={cn(
                "pointer-events-none",
                colorSettings.icon.hover,
                currentUserValue === VOTE_TYPES.LIKE ? colorSettings.icon.active : colorSettings.icon.inactive
              )}
              style={{ width: `${minifiedSize}px`, height: `${minifiedSize}px` }}
            />
          ) : (
            <Icon
              icon="eye-crossed"
              iconSet="new-icons"
              className={cn(
                "pointer-events-none scale-100",
                colorSettings.icon.hover,
                currentUserValue === VOTE_TYPES.DISLIKE ? colorSettings.icon.active : colorSettings.icon.inactive
              )}
              style={{ width: `${minifiedSize}px`, height: `${minifiedSize}px` }}
            />
          )}
        </BaseButton>
      </Ripples>
    );
  }

  return (
    <Ripples color="white">
      <BaseButton aria-label="Vote" onClick={handleClick} async LoadPlaceholder={null} className="h-full">
        <div
          className={cn(
            "inline-flex h-full items-center justify-center rounded-5 bg-new-blue-100 px-10 py-5 text-14 transition-colors duration-150 ease-out hover:bg-new-blue-100/60 active:bg-new-blue-100/60",
            colorSettings.icon.active,
            type === VOTE_TYPES.LIKE && (currentUserValue === VOTE_TYPES.LIKE ? "opacity-70" : "opacity-100"),
            type === VOTE_TYPES.DISLIKE && (currentUserValue === VOTE_TYPES.DISLIKE ? "opacity-70" : "opacity-100")
          )}
        >
          {type === VOTE_TYPES.LIKE && (
            <Icon
              icon={currentUserValue === VOTE_TYPES.LIKE ? "heart" : "heart-empty"}
              className={cn("mr-5 h-15 w-15 flex-shrink-0 lg:mr-10", colorSettings.icon.active)}
            />
          )}
          {type === VOTE_TYPES.DISLIKE && (
            <Icon
              icon={currentUserValue === VOTE_TYPES.DISLIKE ? "eye-crossed" : "eye-crossed-empty"}
              className={cn("mr-5 h-15 w-15 flex-shrink-0 lg:mr-10", colorSettings.icon.active)}
            />
          )}
          <span className="flex-shrink-0 text-13">
            {renderValue(type, currentUserValue)} {btnText}
          </span>
        </div>
      </BaseButton>
    </Ripples>
  );
}

LikeButton.propTypes = {
  type: PropTypes.string,
  likes: PropTypes.shape({
    type: PropTypes.string,
    currentUserValue: PropTypes.string,
    addLike: PropTypes.func,
    dislike: PropTypes.func,
    removeVote: PropTypes.func,
  }).isRequired,
  minified: PropTypes.bool,
  minifiedSize: PropTypes.number,
  btnText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  callback: PropTypes.func,
};

LikeButton.defaultProps = {
  type: VOTE_TYPES.LIKE,
  minified: false,
  minifiedSize: 30,
  btnText: "",
  className: null,
  color: "white",
  callback: null,
};

export default observer(LikeButton);
